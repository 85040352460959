import axios from 'axios';

const http = (options) => {
  const urlData = window.vm.$global && window.vm.$global.getUrlData() || {}
  const ua = window.vm.$global && window.vm.$global.ua()
  let platformType = ''
  if (ua.isMOBILE) {
    platformType = ua.isIOS ? 'mobile-ios' : 'mobile-android'
  } else {
    platformType = ua.isIOS ? 'pc-ios' : 'pc-andorid'
  }
  if (ua.isWX) {
    platformType += '-wechat'
  }
  const appConfig = window.localStorage.getItem('appConfig')
  let version = 'v1.0'
  if (appConfig) {
    try {
      const appInfo = JSON.parse(appConfig).aboutUsIcon
      if (appInfo) {
        version = appInfo.version
      }
    } catch (e) {
      console.log(e);
    }
  }
  const header = {
    "APP-VERSION": version,
    "APP-PLATFORM": "wap",
    "PLATFORM-TYPE": platformType
  }
  if (urlData.channel) {
    header.CHANNEL = urlData.channel
  }
  if (window.localStorage.getItem('JSESSIONID')) {
    header.JSESSIONID = window.localStorage.getItem('JSESSIONID')
  }
  if (options.header) {
    Object.assign(header, options.header)
  }
  const instance = axios({
    timeout: options.timeout || 90000,
    withCredentials: true,
    headers: header,
    transformResponse: [(data)=> {
      try {
        return JSON.parse(data)
      } catch (e) {
        window.vm.$load.hide()
        return data
      }
    }],
    ...options
  });
  return instance
}

axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const toast = window.vm && window.vm.$toast ? window.vm.$toast : (window.$Toast ? window.$Toast : function() {})
  if (response.data && response.data.code === 0) {
    return response.data
  } else if (response.data && response.data.code === 10) {
    toast('请先登录')
    const router = window.vm && window.vm.$router
    router.currentRoute.meta.notKeepAlive = true;
    router.replace('/login')
    return response.data
  } else {
    if (response.data.errorMsg) {
      const msg = response.data.errorMsg
      toast(msg)
    } else {
      toast('出错了')
    }
    return response.data
  }
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});


http.get = (url, options) => {
  return http({...options, params: options.data, method: 'get', url: url})
}

http.post = (url, options) => {
  return http({...options, method: 'post', url: url})
}

http.form = (url, options) => {
  const obj = new FormData()
  const data = options.data
  for(let i in data) {
    if (Object.prototype.toString.call(data[i]) == '[object Array]') {
      // obj.append(i, data[i])
      data[i].forEach(item => {
        obj.append(i, item, item.name ? item.name : undefined)
      })
    }
    obj.append(i, data[i])
  }
  return http({
    ...options, 
    method: 'post', 
    url: url, 
    header: {
      'Content-Type': 'multipart/form-data'
    }, 
    data: obj
  }, )
}

export default http
