import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index.js'
import routerInteceptor from '@/common/js/router.js'
import login from './login'
import post from './post'
import setting from './setting'
import group from './group'
import build from './build'
import home from './home.js'

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '*',
      meta: {
        title: '404'
      },
      redirect: '/404'
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../pages/notFound.vue'),
      meta: {
        title: '404'
      }
    },
    {
      path: '/',
      redirect: '/home'
    },
    ...home,
    ...build,
    ...group,
    ...login,
    ...setting,
    ...post
  ]
})

router.beforeEach((to, from, next) => {
  console.log("router before", to, from)
  
  if(to.path !== '/login' && !localStorage.getItem('JSESSIONID') && !(to.query.JSESSIONID)){
    next({
      path: '/login',
      query: {redirect: to.fullPath}
    });
    return;
  }
  let directPage = sessionStorage.getItem('directPage')
  if (directPage) {
    store.commit('SET_ROUTER', directPage)
  } else {
    let ii = routerInteceptor(to, from)
    if (ii === 0) {
      store.commit('SET_ROUTER', 'fade')
    } else if (ii === 1) {
      store.commit('SET_ROUTER', 'forward')
    } else if (ii === -1) {
      store.commit('SET_ROUTER', 'backward')
    } else {
      store.commit('SET_ROUTER', 'backward')
    }
  }
  setTimeout(() => {
    next()
    document.title = to.meta.title
    if (to.meta.change && typeof to.meta.change == 'function') {
      to.meta.change()
    }
  }, 20)
})

export default router
